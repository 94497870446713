/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const prodDomains = ['spoonx.com']
const checkIsProdEnv = () => {
  const { host } = window.location
  return prodDomains.some(domain => host.includes(domain))
}

// TODO: 先临时插入favcion，后面改成gatsby的plugin
function updateFavicon() {
  const link = document.querySelector('link[rel="icon"]')
  const DEFAULT_FAVICON =
    'https://r.kezaihui.com/client/2022-08-26/spoonx-favicon-32x32.png'

  if (link) {
    link.setAttribute('rel', 'icon')
    link.setAttribute('href', DEFAULT_FAVICON)
  } else {
    const newLink = document.createElement('link')
    newLink.setAttribute('rel', 'icon')
    newLink.setAttribute('href', DEFAULT_FAVICON)
    document.head.appendChild(newLink)
  }
}

function initGoogleAnalyticsAndAds(googleAnalyticsID, googleAdsID) {
  const script = document.createElement('script')
  script.async = true
  script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsID}`

  document.head.appendChild(script)

  window.dataLayer = window.dataLayer || []

  function gtag() {
    window.dataLayer.push(arguments)
  }

  window.gtag = gtag
  gtag('js', new Date())
  gtag('config', googleAnalyticsID)
  gtag('config', googleAdsID)
}

exports.onInitialClientRender = () => {
  updateFavicon()

  if (checkIsProdEnv()) {
    // 在账号 fe@kezaihui.com 名下
    initGoogleAnalyticsAndAds('G-K1EFGKZV1J', 'AW-16448478832')
  }
}

// TODO: 想办法解决下
// exports.onClientEntry = () => { // 似乎没用
//   // page-data.json后面加上时间戳 临时解决s3上没有设置cache-control的问题
//   // gatsby的prefetc是通过xhr实现的 override一下
//   var rawOn = XMLHttpRequest.prototype.open
//   XMLHttpRequest.prototype.open = function (...args) {
//     const [method, url, ...rest] = args
//     if (
//       url.includes('page-data.json')
//       || url.includes('app-data.json')
//       || url.includes('.kezaihui.com')
//     ) return rawOn.call(this, method, `${url}?key=${Date.now()}`,...rest)
//     return rawOn.call(this, ...args)
//   }

// ;[].slice.call(document.getElementsByTagName('link')).forEach(node => {
//   if (node.href.includes('page-data.json') && !node.href.includes('key=')) {
//     var href = node.href
//     node.parentNode.removeChild(node)
//     var tempLink = document.createElement('link')
//     tempLink.href = href + `?key=${Date.now()}`
//     tempLink.crossorigin = 'anonymous'
//     tempLink.as = 'fetch'
//     document.head.appendChild(tempLink)
//   }
// })
// }
